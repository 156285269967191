var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("colgroup", [
        _c("col", { attrs: { width: "130px" } }),
        _c("col", { attrs: { width: "170px" } }),
        _c("col", { attrs: { width: "130px" } }),
        _c("col", { attrs: { width: "170px" } }),
        _c("col", { attrs: { width: "130px" } }),
        _c("col", { attrs: { width: "170px" } }),
        _c("col", { attrs: { width: "130px" } }),
        _c("col", { attrs: { width: "170px" } }),
      ]),
      _c("thead", [
        _c("tr", { staticClass: "ETA_ETD_under_table_tr" }, [
          _c("th", [_vm._v(" VSL/VOY(CS) ")]),
          _c("th", [_vm._v(" Schedule ETA(CS) ")]),
          _c("th", [_vm._v(" VSL/VOY(CS) ")]),
          _c("th", [_vm._v(" AIS ETA(CS) ")]),
          _c("th", [_vm._v(" VSL/VOY(CS) ")]),
          _c("th", [_vm._v(" Schedule ETD(CS) ")]),
          _c("th", [_vm._v(" VSL/VOY(CS) ")]),
          _c("th", [_vm._v(" AIS ETD(CS) ")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }