<template>
  <table>
      <colgroup>
        <col width="130px"><col width="170px"><col width="130px"><col width="170px">
        <col width="130px"><col width="170px"><col width="130px"><col width="170px">
      </colgroup>
      <thead>
        <tr class = "ETA_ETD_under_table_tr">
          <th> VSL/VOY(CS) </th>
          <th> Schedule ETA(CS) </th>
          <th> VSL/VOY(CS) </th>
          <th> AIS ETA(CS) </th>
          <th> VSL/VOY(CS) </th>
          <th> Schedule ETD(CS) </th>
          <th> VSL/VOY(CS) </th>
          <th> AIS ETD(CS) </th>
        </tr>
      </thead>
      <!-- <tbody>
        <tr v-for="(item, idx) in parentInfo" style="height : 25px"
          :key="idx">
            <td>{{ item.tableVslCdA }} {{ item.tableVoyNoA }}</td><td>{{ item.tableSchEta }}</td>
            <td style="color: #008000">{{ item.tableVslCdC }} {{ item.tableVoyNoC }}</td>
            <td style="color: #008000; border-right: 1px solid #e1e3eb;">{{ item.tableAisEta }}</td>
            <td>{{ item.tableVslCdB }} {{ item.tableVoyNoB }}</td><td>{{ item.tableSchEtd }}</td>
            <td style="color: #008000">{{ item.tableVslCdD }} {{ item.tableVoyNoD }}</td>
        </tr>
      </tbody> -->
    </table>
</template>

<script>
export default {
  name: 'CustomerService',
  props: {
    parentInfo: {
      type: Array,
      default: () => {
        return {}
      }
    }
  }
}
</script>
